<template>
  <section class="dress-code">
    <div class="bg">
      <img :src="bg" alt="постер со скилетами"/>
    </div>
    <h1>
      TEMPLE OF GHOST
    </h1>
    <h2 class="dress-code__title">Dress code</h2>
    <div class="dress-code__text">
      <p class="dress-code__item">Дорогие гости!</p>
      <p class="dress-code__item">
        Близится наша встреча 🎃<br/>
        В целях максимально атмосферного погружения в праздник, напоминаем, что наличие образа на вечеринке обязательно!
      </p>
      <p class="dress-code__item"><b>Базовый дресс код:</b> классический Halloween в голивудском стиле или black tie.
        В случае отсутствия полноценного костюма допустим тематический гримм или make up.
      </p>
      <p class="dress-code__item">
        See you! 👻
      </p>
    </div>
    <div class="addable-hover-info">
      <div
          class="date-container"
          :class="{ tba: main.date.str === 'TBA' }"
      >
        <div class="date">
          {{ main.date.str }}
        </div>
        <div class="weekday">
          {{ main.date.minWeek }}
        </div>
      </div>
      <button
          @click="ticketsWidgetCall(main.ticketsWidget)"
          :disabled="btnText(main.minPrice).sold"
          class="buy-tickets main-t"
      >
        <img src="/static/icons/ticket.svg" alt=""/>
        <p>
          {{ btnText('Купить билет').str }}
        </p>
      </button>
      <div class="age">{{ main.minAge }}+</div>
    </div>
    <div class="dress-code__reference">
      <gallery :images="images"/>
    </div>
  </section>
</template>
<script>
import Gallery from "@/components/Gallery.vue";
import bg from '../assets/dressPage/bg.webp';
import image_1 from '../assets/dressPage/img_1.webp';
import image_2 from '../assets/dressPage/img_2.webp';
import image_3 from '../assets/dressPage/img_3.webp';
import image_4 from '../assets/dressPage/img_4.webp';
import image_5 from '../assets/dressPage/img_5.webp';
import image_6 from '../assets/dressPage/img_6.webp';
import {http} from "@/components/services/httpService";
import parseDateMixin from "./parseDateMixin";

export default {
  name: 'DressPage',
  mixins: [parseDateMixin],
  components: {Gallery},
  data() {
    return {
      main: {
        minPrice: 0,
        minAge: 0,
        ticketsBlock: {
          tickets: [],
          widget: "",
        },
      },
      bg: bg,
      images: [image_1, image_2, image_3, image_4, image_5, image_6],
    }
  },
  methods: {
    getEventInfo() {
      http.get(
          `/api/events/ghost?lang=${this.$i18n.locale}`
      ).then((response) => {
        if (response.status < 400) {
          document.documentElement.scrollTop = 0;
          this.saveAllData(response.data);
        }
      });
    },
    ticketsWidgetCall(str) {
      if (new RegExp("^https?:\/\/[^.]+\.[^.]+.+$").test(str)) {
        document.location.href = str;
      } else {
        eval(str);
      }
    },
    saveAllData(data) {
      this.main = {
        ...data.baseBlock,
        date: this.parseDate(data.baseBlock.date),
      };
      this.artists = data.artistsBlock.artists;
      this.ticketsBlock = data.ticketsBlock;
      this.mediaBlock = data.mediaBlock;
      this.categories = data.categoriesBlock.categories;
      this.similarEvents = data.similarBlock.events.map((e) => ({
        ...e,
        date: this.parseDate(e.date),
      }));
      document.title = this.main.name;
    },
  },
  beforeRouteEnter(to, from, next) {
    const locale = localStorage.getItem("locale");

    http.get(
        `/api/events/ghost?lang=${locale ? locale : "ru-RU"}`
    ).then((response) => {
      if (response.status < 400) {
        next((vm) => {
          vm.$el.scrollTop = 0;
          vm.saveAllData(response.data);
        });
      }
    });
  },
}

</script>
<style scoped lang="scss">
@import "../components/styles/config.scss";

.dress-code {
  margin-top: 0;
  padding: 0 ptr(15px);

  h1 {
    font-size: ptr(140px);
    font-weight: 100;
    margin: 0 0 0 ptr(-10px);
    text-transform: uppercase;
    @media (max-width: 1024px) {
      font-size: ptr(42px);
      margin: 0 0 0 ptr(-2px);
    }
  }

  &__text {
    max-width: ptr(790px);
    font-size: ptr(16px);
    width: 100%;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;

  img {
    display: block;
    opacity: 0.5;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 40%;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
  }

  @media(max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 120vh;
    }
  }
}

.addable-hover-info {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: auto min-content;
  gap: ptr(20px) ptr(14px);
}

.date-container {
  width: 232px;
  height: 46px;
  font-size: 14px;
  margin: 0;
  @media(max-width: 900px) {
    width: ptr(225px);
    height: ptr(46px);
    font-size: ptr(14px);
  }

  &::after {
    height: ptr(34px);
  }
}

.buy-tickets {
  grid-column: span 2;
  grid-row: 2;
  width: 300px;
  height: 53px;

  @media(max-width: 900px) {
    width: ptr(290px);
    height: ptr(53px);
  }
}
</style>

