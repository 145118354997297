import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "@/views/MainPage";
import DressPage from "@/views/DressPage";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Main",
		component: MainPage,
	},
	{
		path: "/dresscode",
		name: "Dress",
		component: DressPage,
	},

];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
