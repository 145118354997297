<template>
  <section class="page">
    <div class="segment-1">
      <div class="artist-image">
        <img :src="main.poster" alt=""/>
      </div>
      <h1>
        {{ main.name }}
      </h1>
      <div class="description">
        {{ main.description }}
        <div class="scroll-arrow_mob">
          <img :src="arrow" alt="arrow"/>
        </div>
      </div>
      <div class="addable-hover-info">
        <div
            class="date-container"
            :class="{ tba: main.date.str === 'TBA' }"
        >
          <div class="date">
            {{ main.date.str }}
          </div>
          <div class="weekday">
            {{ main.date.minWeek }}
          </div>
        </div>
        <button
            @click="ticketsWidgetCall(main.ticketsWidget)"
            :disabled="btnText(main.minPrice).sold"
            class="buy-tickets main-t"
        >
          <img src="/static/icons/ticket.svg" alt=""/>
          <p>
            {{ btnText('Купить билет').str }}
          </p>
        </button>
        <div class="age">{{ main.minAge }}+</div>
      </div>
      <router-link to="/dresscode" class="dress-btn"
      >
        <span>Dress code</span>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.443237 13.8032L13.4432 0.803223M13.4432 0.803223L2.00324 0.803223M13.4432 0.803223C13.4432 0.803223 13.4432 9.19713 13.4432 12.2432" stroke="white" stroke-width="0.5" stroke-linecap="round"/>
        </svg>

      </router-link>
      <div class="scroll-arrow">
        <img :src="arrow" alt="arrow"/>
      </div>
    </div>
    <div class="organizers">
			<span
          :style="{
					opacity: main.partnersLabel.length > 0 ? 1 : 0,
				}"
      >{{ main.partnersLabel }}:</span
      >
      <div class="sub-imgs-mass">
        <div
            class="orgs"
            :style="{
						opacity: main.partners.length > 0 ? 1 : 0,
					}"
        >
          <img
              v-for="(o, k) in main.partners"
              :key="k"
              :src="o"
              alt=""
          />
        </div>
        <div
            v-if="
						main.socials.facebook ||
							main.socials.vk ||
							main.socials.instagram
					"
            class="socials"
        >
          <p>{{ $t("eGroup") }}:</p>
          <a
              :target="'_blank'"
              :href="'https://vk.com/templeofghostt'">
            <img src="/static/icons/vk.svg" alt="ссылка на вконтакте"/>
          </a>
          <a
              :target="'_blank'"
              class="tlg"
              :href="'https://t.me/templeofghost'"
          >
            <img src="/static/icons/telegram.svg" style="filter: none" alt="ссылка на telegram"/>
          </a>
          <a
              :target="'_blank'"
              :href="'https://www.instagram.com/templeofghostttt'"
          >
            <img src="/static/icons/instagram.svg" alt="ссылка на instagram"/>
          </a>
        </div>
      </div>
    </div>
    <div class="artists">
      <h2>LINE UP</h2>
      <slider-component :mass="artists">
        <template v-slot="content">
          <div class="content-info">
            <h3>
              {{ content.content.name }}
            </h3>
            <div class="c-description">
              <template v-for="(o) in content.content.description.split('; ')">
                <div v-bind:key="o">
                  {{ o }}
                </div>
              </template>
            </div>
            <button
                @click="ticketsWidgetCall(main.ticketsWidget)"
                :disabled="btnText(main.minPrice).sold"
                class="buy-tickets"
            >
              <img src="/static/icons/ticket.svg" alt=""/>
              <p>
                {{ btnText("Купить билеты").str }}
              </p>
            </button>
          </div>
        </template>
      </slider-component>
    </div>
    <dress-code :show="isShowDress"/>
    <div class="tickets">
      <h2>{{ $t("tickets") }}</h2>
      <slider-component :mass="ticketsBlock.tickets" :locked="true">
        <template v-slot="content">
          <div class="t-description">
            {{ content.content.description }}
            <div v-if="content.content.site" class="socials-in">
              <a
                  v-if="content.content.site"
                  class="site"
                  :href="content.content.site"
              >
                {{ "Купить билет" }}
              </a>
            </div>
          </div>
        </template>
      </slider-component>
    </div>
    <div class="video-block">
      <h2 class="video-block__title">TEMPLE OF GHOST'23</h2>
      <video class="video-block__video" poster="/static/img/poster.jpg" controls>
        <source src="../../public/static/video/Хэллоуин_2.mp4"/>
      </video>
    </div>
    <div class="media-files">
      <h2>TEMPLE OF GHOST'23</h2>
      <gallery :video="mediaBlock.video" :images="mediaBlock.images"/>
    </div>
    <div class="about">
      <h2>{{ $t("about") }}</h2>
      <div class="about-wrapper">
        <img src="../assets/scheme.jpg"/>
        <div class="about-info">
          <div class="about-info__item">
            <div class="about-info__value">5</div>
            <div class="about-info__label">сцен</div>
          </div>
          <div class="about-info__item">
            <div class="about-info__value">4000</div>
            <div class="about-info__label">вместимость</div>
          </div>
          <div class="about-info__item">
            <div class="about-info__value">5000</div>
            <div class="about-info__label">площадь м²</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="similarEvents.length > 0" class="similar-events">
      <h2>{{ $t("similarEvents") }}</h2>
      <div class="events-container">
        <template v-for="(e, k) in similarEvents">
          <div v-if="!isMobile" :key="k" class="event-container">
            <div class="event-data">
              <div class="number">
                {{ e.date.mDate }}
              </div>
              <span>
								{{ e.date.maxWeek }}
							</span>
            </div>
            <div class="event-info">
              <div class="tags">
                <div
                    v-for="(t, tt) in e.tags"
                    :key="tt"
                    class="tag"
                >
                  {{ t }}
                </div>
              </div>
              <router-link :to="`${e.url}`" class="artist-name">
                {{ e.name }}
              </router-link>
              <div class="event-description">
                {{ e.summary }}
              </div>
            </div>
            <div class="event-age">{{ e.minAge }}+</div>
            <button
                @click="ticketsWidgetCall(e.ticketsWidget)"
                :disabled="btnText(e.minPrice).sold"
                class="buy-tickets"
            >
              <img src="/static/icons/ticket.svg" alt=""/>
              <p>
                {{ btnText(e.minPrice).str }}
              </p>
            </button>
          </div>
          <mobile-event-component
              v-else
              :key="k"
              :data="e"
              :paddingOff="true"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SliderComponent from "../components/SliderComponent.vue";
import DressCode from "../components/DressCode.vue";
import MobileEventComponent from "./MobileEventComponent.vue";
import {http} from "../components/services/httpService";
import parseDateMixin from "./parseDateMixin";
import Gallery from "../components/Gallery.vue";
import arrow from '../assets/arrow.svg';

export default {
  name: "EventPage",
  mixins: [parseDateMixin],
  components: {SliderComponent, MobileEventComponent, Gallery, DressCode},
  props: {
    isMobile: {
      default: false,
    },
  },
  data() {
    return {
      main: {
        id: 0,
        url: "",
        name: "",
        description: "",
        poster: "",
        date: "",
        minPrice: 0,
        minAge: 0,
        tags: [],
        partnersLabel: "",
        partners: [],
        socials: {
          vk: "",
          instagram: "",
          facebook: "",
        },
      },
      artists: [],
      ticketsBlock: {
        tickets: [],
        widget: "",
      },
      mediaBlock: {
        video: "",
        images: [],
      },
      categories: [],
      similarEvents: [],
      media: {
        x: 0,
        pos: 0,
        container: null,
      },
      isShowDress: false,
      arrow: arrow
    };
  },
  methods: {
    getEventInfo() {
      http.get(
          `/api/events/ghost?lang=${this.$i18n.locale}`
      ).then((response) => {
        if (response.status < 400) {
          document.documentElement.scrollTop = 0;
          this.saveAllData(response.data);
        }
      });
    },
    ticketsWidgetCall(str) {
      if (new RegExp("^https?:\/\/[^.]+\.[^.]+.+$").test(str)) {
        document.location.href = str;
      } else {
        eval(str);
      }
    },

    saveAllData(data) {
      this.main = {
        ...data.baseBlock,
        date: this.parseDate(data.baseBlock.date),
      };
      this.artists = data.artistsBlock.artists;
      this.ticketsBlock = data.ticketsBlock;
      this.mediaBlock = data.mediaBlock;
      this.categories = data.categoriesBlock.categories;
      this.similarEvents = data.similarBlock.events.map((e) => ({
        ...e,
        date: this.parseDate(e.date),
      }));
      document.title = this.main.name;
    },
  },
  computed: {
    parseYoutubeUrl() {
      const url = this.mediaBlock.video.split("=");
      let src = url[1];
      if (url.length > 2) {
        src = src.split("&")[0];
        src += "?start=" + url[2];
      }
      return src;
    },
  },
  beforeRouteEnter(to, from, next) {
    const locale = localStorage.getItem("locale");

    http.get(
        `/api/events/ghost?lang=${locale ? locale : "ru-RU"}`
    ).then((response) => {
      if (response.status < 400) {
        next((vm) => {
          vm.$el.scrollTop = 0;
          vm.saveAllData(response.data);
        });
      }
    });
  },
  watch: {
    "$route.params"() {
      this.getEventInfo();
    },
    "$i18n.locale": function () {
      this.getEventInfo();
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    if (this.main.url.includes('ghost')) {
      this.isShowDress = true
    } else {
      this.isShowDress = false
    }

    const arrow = document.querySelector('.scroll-arrow');
    const arrowMob = document.querySelector('.scroll-arrow_mob');
    window.addEventListener("scroll", () => {
      if (window.scrollY > document.body.scrollHeight - window.screen.height) {
        arrow.setAttribute('style', 'opacity: 0;')
        arrowMob.setAttribute('style', 'opacity: 0;')
      } else {
        arrow.setAttribute('style', 'opacity: 1;')
        arrowMob.setAttribute('style', 'opacity: 1;')
      }
    });

  },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

@import "./styles/event.scss";
</style>
