<template>
	<article>
		<div class="line-container">
			<div class="event-place-description-pages" @touchmove="clearInt">
				<div
					v-for="(h, k) in mass"
					:key="k"
					ref="topTxt"
					@click="setSliderPage(k)"
					class="pages-header"
					:class="{
						chosen: sliderOptions.page === k,
					}"
				>
					{{ h.name.replace('#', '') }}
				</div>
				<div
					class="active-line"
					:style="{
						left: sliderOptions.line.offset + 'px',
						width: sliderOptions.line.width + 'px',
					}"
				></div>
			</div>
		</div>
		<div class="event-place-pages-content">
			<div @touchmove="moving" @touchend="moveEnd" class="corousel-body">
				<div
					class="slider-component"
					:class="{
						'full-mode': fullMode,
					}"
					:style="{
						transform: `translate3d(${sliderOptions.translate.current}px,0,0)`,
						'transition-duration': sliderOptions.translate.move
							? '0s'
							: '.4s',
					}"
				>
					<div
						v-for="(h, k) in mass"
						:key="k"
						class="event-place-content"
						:style="{
							width: slideWidth + 'px',
						}"
						:class="{ inactive: sliderOptions.page !== k }"
					>
						<slot :content="h"></slot>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
export default {
	props: {
		fullMode: {
			default: true,
		},
		locked: {
			default: false,
		},
		mass: {
			default: () => [
				{
					name: "Test",
				},
				{
					name: "Test2",
				},
			],
		},
	},
	data() {
		return {
			sliderOptions: {
				page: 0,
				line: {
					width: 0,
					offset: 0,
				},
				translate: {
					move: false,
					start: {
						x: 0,
						y: 0,
					},
					current: 0,
				},
			},
			slideWidth: 1630,
			interval: null,
		};
	},
	methods: {
		moving(e) {
			if (this.locked) {
				return;
			}
			const t = this.sliderOptions.translate;
			t.move = true;
			if (this.sliderOptions.translate.start.x === 0) {
				t.start = {
					x: e.touches[0].pageX,
					y: e.touches[0].screenY,
				};
			}
			if (
				Math.abs(t.start.x - e.touches[0].pageX) >
				Math.abs(t.start.y - e.touches[0].screenY)
			)
				t.current =
					-this.sliderOptions.page * this.slideWidth -
					(t.start.x - e.touches[0].pageX);
		},
		moveEnd() {
			if (this.locked) {
				return;
			}
			const s = this.sliderOptions;
			s.translate.move = false;
			s.translate.start = {
				x: 0,
				y: 0,
			};
			if (
				s.page > 0 &&
				-(s.page - 0.3) * this.slideWidth < s.translate.current
			) {
				s.page -= 1;
			} else if (
				s.page < this.mass.length - 1 &&
				-(s.page + 0.3) * this.slideWidth > s.translate.current
			) {
				s.page += 1;
			}
			s.translate.current = -s.page * this.slideWidth;
			this.setSliderPage(s.page);
		},
		setSliderPage(k) {
			const el = this.$refs.topTxt[k];
			this.sliderOptions.page = k;
			this.setTopLine(k);
			this.$el.querySelector(".slider-component").style.height =
				this.$el.querySelectorAll(".event-place-content")[k]
					.scrollHeight + "px";
			this.sliderOptions.translate.current =
				-this.sliderOptions.page * this.slideWidth;
			const scrl = el.closest(".event-place-description-pages");
			const maxScrl = scrl.scrollWidth - scrl.offsetWidth;
			const offset = el.offsetLeft > maxScrl ? maxScrl : el.offsetLeft;
			let part = 1;
			if(this.interval !== null){
				this.clearInt();
			}
			this.interval = setInterval(() => {
				const diff = offset - scrl.scrollLeft;
				if (diff === 0 || (diff > -1 && diff < 1)) {
					this.clearInt();
				} else if (diff < -10 || diff > 10) {
					part = diff > 0 ? 10 : -10;
				} else if (diff > -2 || diff < 2) {
					part = diff > 0 ? 1 : -1;
				}
				scrl.scrollLeft += part;
			}, 10);
		},
		setTopLine(k) {
			const el = this.$refs.topTxt[k];
			this.sliderOptions.line = {
				width: el.scrollWidth,
				offset: el.offsetLeft,
			};
		},
		clearInt() {
			clearInterval(this.interval);
		},
	},
	watch: {
		mass() {
			if (this.$el.clientWidth !== 0)
				setTimeout(() => {
					const contentWidth = this.$el.clientWidth;
					this.setTopLine(this.sliderOptions.page);
					const isMobile = this.$el.clientWidth < 1000;
					if (!isMobile) {
						this.sliderOptions.translate.move = true;
					} else {
						this.$el.querySelector(
							".slider-component"
						).style.height =
							this.$el.querySelector(".event-place-content")
								.scrollHeight + "px";
					}
					if (this.fullMode || !isMobile) {
						this.slideWidth = contentWidth;
					} else {
						this.slideWidth = contentWidth - 40;
					}
				}, 10);
			else
				this.sliderOptions.line = {
					width: 0,
					offset: 0,
				};
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

article {
	position: relative;
}

.event-place-description-pages {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	gap: ptr(80px);
	position: relative;
	font-size: ptr(30px);
	font-weight: 100;
}
.line-container {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: ptr(1px);
		background: rgba(255, 255, 255, 0.21);
	}
}
.pages-header {
	padding-bottom: ptr(16px);
	opacity: 0.5;
	transition: $trs;
	cursor: pointer;
}
.corousel-body {
	width: ptr(1639px);
	overflow: hidden;
}
.slider-component {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
}
.event-place-pages-content {
	margin-top: ptr(77px);
}
.active-line {
	background: white;
	height: ptr(3px);
	position: absolute;
	transition: $trs;
	bottom: 0;
}
.event-place-content {
	display: grid;
	grid-template-columns: auto max-content;
	gap: ptr(100px);
	transition: opacity 0.6s ease-in;
	opacity: 1;
}
.inactive {
	transition: none;
	opacity: 0;
}
@media (max-width: 1024px) {
	.event-place-description-pages {
		font-size: ptr(16px);
		gap: ptr(26px);
		overflow-x: scroll;
	}
	.pages-header {
		padding-bottom: ptr(9px);
	}
	.event-place-pages-content {
		margin-top: ptr(28px);
	}
	.corousel-body {
		width: ptr(310px);
	}
	.slider-component {
		transition: unset;
		transition: $trs;
		transition-timing-function: cubic-bezier(0.25, 0.74, 0.22, 0.99);
	}
	.event-place-content {
		grid-template-columns: ptr(254px);
		grid-template-rows: repeat(2, max-content);
		height: fit-content;
		transition: opacity $trs;
		gap: ptr(30px);
	}
	.full-mode {
		grid-template-columns: ptr(300px);
		.event-place-content {
			grid-template-columns: ptr(300px);
		}
		.inactive {
			opacity: 0;
		}
	}
	.inactive {
		opacity: 0.3;
	}
}
</style>
