export default {
    methods:{
        parseDate(str){
            if(str === "TBA"){
                return{
                    mDate: `TBA`,
                    minWeek: '',
                    maxWeek: '',
                    maxMonth: '',
                    str: `TBA`
                }
            }
			const d = new Date(str);
			const chZ = (val) => {
				return val > 9 ? val : "0" + val
			}
			const m = chZ(d.getMonth() + 1);
            const locale = this.$i18n.locale === "ru-RU" ? "ru" : "en";
			const minW = d.toLocaleDateString(locale, {weekday: "short"});
			const maxW = d.toLocaleDateString(locale, {weekday: "long"});	
			const date = chZ(d.getDate());
			let time = new Date(d.getTime() + new Date().getTimezoneOffset() * 60000);
			time = chZ(time.getHours() + 3) +":"+ chZ(time.getMinutes());
			let maxM = d.toLocaleDateString(locale, {month: "long"});
            if(locale === "ru"){
                maxM = maxM.split("");
                switch (maxM[maxM.length-1]) {
                    case "ь":
                        maxM[maxM.length-1] = "я"
                    break;
                    case "й":
                        maxM[maxM.length-1] = "я"
                    break;
                    case "т":
                        maxM[maxM.length] = "а"
                    break;
                    default:
                    break;
                }
                maxM = maxM.join("");
            }
			return{
				mDate: `${date}.${m}`,
				minWeek: minW,
				maxWeek: maxW,
				maxMonth: maxM,
				str: `${date} ${maxM} в ${time}`
			}
		},
        btnText(str){
			if(isNaN(parseInt(str))){
				return {
                    str: str,
                    sold: true
                }
			}
			return {
                str: `${this.$t('buyText')} ${str} ₽`,
                sold: false 
            };
		},
    }
}