<template >
  <div v-if="show" class="dress-code">
    <h2 class="dress-code__title">Дресс-код Temple of Ghost</h2>
    <div class="dress-code__text">
      <p class="dress-code__item"><b>Special dress code:</b> трансформация альтер-эго. Дайте волю своей теневой стороне, явите миру сущность, скрытую от глаз. </p>
      <p class="dress-code__item"><b>Базовый дресс код:</b> классический Halloween в голивудском стиле или black tie.</p>
      <p class="dress-code__item">В случае отсутствия полноценного костюма допустим тематический гримм или make up.</p>
      <p class="dress-code__item" style="color: #f44f42">Наличие образа обязательно*</p>
    </div>
    <div class="dress-code__reference">
      <gallery :images="images"/>
    </div>
  </div>
</template>

<script>
import image_1 from '../assets/dressCode/photo_2023-10-26_15-28-35.jpg';
import image_2 from '../assets/dressCode/photo_2023-10-26_15-28-37.jpg';
import image_3 from '../assets/dressCode/photo_2023-10-26_15-28-38.jpg';
import image_4 from '../assets/dressCode/photo_2023-10-26_15-28-39.jpg';
import image_5 from '../assets/dressCode/photo_2023-10-26_15-28-40.jpg';
import image_6 from '../assets/dressCode/photo_2023-10-26_15-28-41.jpg';
import image_7 from '../assets/dressCode/photo_2023-10-26_15-28-43.jpg';
import image_8 from '../assets/dressCode/photo_2023-10-26_15-28-44.jpg';
import image_9 from '../assets/dressCode/photo_2023-10-26_15-28-45.jpg';
import image_10 from '../assets/dressCode/photo_2023-10-30_14-59-27.jpg';


import Gallery from "@/components/Gallery.vue";

export default {
  props: {
    show:{
      default: false
    }
  },
  components: {Gallery},
  data() {
    return {
      images: [image_10, image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9]
    }
  }
}
</script>

<style lang="scss">
.dress-code {
  @import "../components/styles/config.scss";
  margin-top: ptr(290px);
  @media(max-width: 1024px) {
    margin-top: ptr(90px);
  }

  &__title {
    font-size: ptr(74px);
    margin: 0 0 ptr(46px);
    font-weight: 100;
    @media(max-width: 1024px) {
      font-size: ptr(33px);
      margin: 0 0 ptr(23px);
    }
  }

  &__text {
    max-width: ptr(790px);
    width: 100%;
  }

  &__item {
    li {
      list-style: disc;
      list-style-position: inside;

      &:not(:last-child) {
        margin-bottom: ptr(20px);
      }
    }
  }
}
</style>
