var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-event",class:{
		trs: _vm.trs,
		'padding-off': _vm.paddingOff,
	},style:({
		height: _vm.height,
	})},[_c('div',{ref:"test",staticClass:"top-mobile-group",class:_vm.checkWidth(_vm.data.name),on:{"click":function($event){_vm.opened ? _vm.removeOpenEl($event) : _vm.addOpenEl($event)}}},[_c('div',{staticClass:"mobile-date"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.data.date.mDate)+" ")]),_c('div',{staticClass:"weekday"},[_vm._v(" "+_vm._s(_vm.data.date.maxWeek)+" ")]),(_vm.data.sticker)?_c('div',{staticClass:"sticker",style:({
					background: _vm.data.sticker.color,
				})},[_vm._v(" "+_vm._s(_vm.data.sticker.message)+" ")]):_vm._e()]),_c('div',{staticClass:"mobile-artist"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]),_c('svg',{attrs:{"viewBox":"0 0 99 84","fill":"none"}},[_c('path',{attrs:{"d":"M56.7998 0L98.7998 42L56.7998 84H53.0198L93.7598 43.26H0.799805V40.74H93.7598L53.0198 0H56.7998Z","fill":"white"}})])]),_c('div',{staticClass:"mobile-hover-group"},[_c('div',{staticClass:"mobile-description"},[_vm._v(" "+_vm._s(_vm.data.summary)+" ")]),_c('div',{staticClass:"tags"},_vm._l((_vm.data.tags),function(t,tt){return _c('div',{key:tt,staticClass:"tag"},[_vm._v(" "+_vm._s(t)+" ")])}),0),_c('div',{staticClass:"mobile-age"},[_vm._v(_vm._s(_vm.data.minAge)+"+")]),_c('button',{staticClass:"buy-tickets main-t",on:{"click":function($event){return _vm.$router.push(
					_vm.galleryPage ? '/past' + _vm.data.url + '/true' : _vm.data.url
				)}}},[(!_vm.galleryPage)?_c('img',{attrs:{"src":"/static/icons/ticket.svg","alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.galleryPage ? _vm.$t("checkPhoto") : _vm.btnText(_vm.data.minPrice).str)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }